.bullets {
  $base-line-height: 1.5em !default;
  $dark-gray: #333 !default;
  $base-font-color: $dark-gray !default;
  $icon-bullet-size: 2.8em;

  @include display(flex);
  @include flex-wrap(wrap);
  margin-bottom: $base-line-height;
  margin: 1em;
  overflow: auto;
  padding: 0;

  .bullets__bullet {
    @include flex-basis(20em);
    @include flex-grow(1);
    list-style: none;
  }

  &.bullets--block {
    .bullets__bullet {
      @include flex-basis(100%);
    }
  }

  .bullet__icon {
    background: $brand-primary;
    border-radius: 50%;
    float: left;
    height: $icon-bullet-size;
    padding-top: $icon-bullet-size / 4;
    text-align: center;
    width: $icon-bullet-size;
    color: #fff;
    i {
      @include responsive-font-size(1.6em);
    }
  }

  .bullet__content {
    margin-left: $icon-bullet-size * 1.4;
    margin-bottom: 2em;
    color: $text-color;
    line-height: $base-line-height;
    .content__title {
      border-bottom: 1px solid $body-bg;
      display: block;
      max-width: 90%;
      font-size: $icon-bullet-size / 2;
      margin: 0 0 ($icon-bullet-size / 6) 0;
      padding: $icon-bullet-size / 7 0;
    }
  }

}
