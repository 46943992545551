.item-list {
	padding: 0;
	margin: 2em auto;
	border-radius: 3px;
	&.item-list--separated {
		//margin-bottom: 1em;
		.item-list__item {
			margin-bottom: 1em;
			@extend .card;
			border-radius: 3px;
		}

		.item-list__item + .item-list__item {
			border-top: none !important;
		}
	}
}

.item-list--condensed {
	margin: .5em 0;
}

.item-list--compact {
	margin: 0;
	.item__description {
		//display: none;
	}
}

.item-list--gold-consultant, .item-list--gold-organizer {
	//border-top: solid $gold-plan-color 5px;
}

.item-list__title {
	color: $brand-primary;
	padding: 15px;
	border-bottom: solid $border-color-base 1px;
}

.item-list__item {
	margin: 0;
	position: relative;
	display: block;
	overflow: hidden !important;
	line-height: 1.6;
	color: $text-color;
	@include clearfix;
	a:hover, &:hover, &:focus, &:active {
		text-decoration: none !important;
		outline: none !important;
	}
	.item__title {
		margin: 0;
		padding: 5px 15px;
		@extend .bg-primary;
	}
}

.item__image {
	padding: 0;
	img {
		@extend .img-responsive;
	}
	@media (min-width: $screen-sm-min) {
		@include span-columns(4, block-collapse);
		img {
			min-width: 100%;
			margin-left: 0 !important;
		}
	}
	@media (min-width: $screen-md-min) {
		@include span-columns(3, block-collapse);
	}
}

.item__content {
	@extend .pad-horizontal;
	@media (min-width: $screen-sm-min) {
		@include span-columns(8, block-collapse);
	}
	@media (min-width: $screen-md-min) {
		@include span-columns(9, block-collapse);
	}
	.content__title {
		line-height: 1.8;
		margin: 0;
		border-bottom: solid $border-color-base 1px;
		border-top: solid $border-color-base 1px;
		text-align: center;
		@media (min-width: $screen-sm-min) {
			text-align: left;
			border: none;
			i {
				display: inline-block;
			}
		}
	}
	.content__description {
		margin-top: 10px;
	}
	.content__footer {
		border-top: solid $border-color-base 1px;
		margin-top: 10px;
		@extend .pad-vertical;
		@media (min-width: $screen-sm-min) {
			border: none;
		}
	}
}

.listing__image {
	overflow: hidden;
	@include pad;
	img {
		border-radius: 100%;
		width: 40%;
		@extend .card;
		margin: 0 0 0 30%;
		border: solid white 3px;
	}
    
}

.item-list--wide {
	.listing__image {
		@media (min-width: $screen-sm-min) {
			@include span-columns(4, block-collapse);
			img {
				min-width: 100%;
				margin-left: 0 !important;
			}
		}
		@media (min-width: $screen-md-min) {
			@include span-columns(3, block-collapse);
		}
	}
	.listing__content {
		@media (min-width: $screen-sm-min) {
			@include span-columns(8, block-collapse);
		}
		@media (min-width: $screen-md-min) {
			@include span-columns(9, block-collapse);
		}
		.listing__title {
			@media (min-width: $screen-sm-min) {
				text-align: left;
				border-top: none;
				i {
					display: inline-block;
				}
			}
		}
	}
	.listing__actions {
		overflow: hidden;
		@media (min-width: $screen-md-min) {
			@include span-columns(6);
			border-radius: 30px;
		}
		a {
			color: white;
			span {
				@media (min-width: $screen-sm-min) {
					display: inline-block;
				}
			}
		}
	}
}

.listing__content {
	.listing__title {
		line-height: 1.8;
		margin: 0;
		border-bottom: solid $border-color-base 1px;
		border-top: solid $border-color-base 1px;
		text-align: center;
		i {
			display: none;
		}
	}
}

.listing__actions {
	display: table;
  	width: 100%;
  	a {
		display: table-cell;
    	width: 1%;
    	border-radius: 0 !important;
    	border-width: 0 !important;
    	@include box-shadow(none);
    	@include button-size($padding-large-vertical, $padding-large-horizontal, 18px, $line-height-large, $btn-border-radius-large);
		&:hover {
			background: $border-color-base;
		}
		span {
			display: none;
		}
	}
	a + a {
		//border-left: solid $border-color-base 1px !important;
	}
}

.item-list--premium, .item-list--premium {
	border-top: solid $premium-plan-color 5px;
}

.item-list--featured, .item-list--featured {
	border-top: solid $featured-plan-color 5px;
}

.item-list__item + .item-list__item {
	border-top: solid rgba(0, 0, 0, .08) 5px !important;
}

.item-list__item--premium, .item-list__item--premium {
	border-top: solid $premium-plan-color 5px;
}

.item-list__item--featured, .item-list__item--featured {
	border-top: solid $featured-plan-color 5px;
}

.item__title {
	margin: 0 0 5px;
	line-height: 1.4;
}

.item__phone {
	font-size: 1.1em;
}

.item__link {

}

.item__description {
	padding: 5px 0;
}

.item__plan {

}

.item__details {

}

.details__detail {
	display: block;
}

.item__dates {

}

.dates__days {
	display: block;
}

.dates__time {
	display: block;
}

.dates__message {
	display: block;
	color: $gray-light;
}

.item__plan--premium, .item__plan--premium {
	color: $premium-plan-color;
}

.item__plan--featured, .item__plan--featured {
	color: $featured-plan-color;
}

.item__plan--basic, .item__plan--basic {
	color: $basic-plan-color;
}

.item__plan--free, .item__plan--free {
	color: white;
}

.item__location {
	display: block;
	margin: 0;
	padding: 0;
	text-align: right;
}
.item__location--left-aligned {
	text-align: left;
}

.location__street {
	display: block;
}



.item__attribute-list {

}

.attribute-list {

}

.attribute-list__attribute {
	display: block;
}

.attribute-list--justified {
	display: table;
	.attribute-list__attribute {
		display: table-row;
	}
	.attribute__key, .attribute__value {
		display: table-cell;
		padding: 0 1em;
	}
	.attribute__key {
		text-align: right;
	}
}

.attribute__key {
	font-weight: bold;
}
.attribute__key--basic {
	font-weight: normal;
}

.attribute__value {

}

.attribute__value--strong {
	font-weight: bold;
}

.value__success {
	padding: 0 1%;
	background: $brand-success;
	color: #fff;
}

.value__error {
	padding: 0 1%;
	background: $brand-danger;
	color: #fff;
}