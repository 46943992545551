.modal {
  $base-border-color: #dcdcdc !default;
  $base-border-radius: 3px !default;
  $base-background-color: #fff !default;
  $base-font-size: 1em !default;
  $base-line-height: 1.5em !default;
  $action-color: #477dca !default;
  $dark-gray: #333 !default;
  $light-gray: #ddd !default;
  $medium-screen: em(640) !default;
  $large-screen: em(860) !default;
  $base-font-color: $dark-gray !default;
  $modal-padding: 3em;
  $modal-background: $base-background-color;
  $modal-close-color: $light-gray;
  $modal-image-height: 135px;
  $modal-image-width: $modal-image-height;
  $modal-trigger-image-width: 300px;

  label {
    cursor: pointer;
    margin-bottom: 0;
  }

  label img {
    border-radius: $modal-trigger-image-width / 2;
    display: block;
    max-width: $modal-trigger-image-width;
  }

  .modal-state {
    display: none;
  }

  .modal-trigger {
    padding: 0.8em 1em;
  }

  .modal-fade-screen { // overlay
    @include transition(opacity 0.25s ease);
    @include position(fixed, 0);
    background-color: rgba(#000, 0.65);
    opacity: 0;
    padding-top: 0.6em;
    text-align: left;
    visibility: hidden;
    z-index: 1040;

    @media (min-width: $screen-lg-min) {
      padding-top: 2em;
    }

    .modal-bg {
      @include position(absolute, 0);
      cursor: pointer;
    }
  }

  .modal-close {
    @include position(absolute, ($modal-padding /4) ($modal-padding /4) null null);
    @include size(1.5em);
    background: $modal-background;
    cursor: pointer;
    
    &:after,
    &:before {
      @include position(absolute, 3px 3px 0 50%);
      @include transform(rotate(45deg));
      @include size(0.15em 1.5em);
      background: $modal-close-color;
      content: '';
      display: block;
      margin: -3px 0 0 -1px;
    }

    &:hover:after,
    &:hover:before {
      background: darken($modal-close-color, 10%);
    }

    &:before {
      @include transform(rotate(-45deg));
    }
  }

  .modal-inner {
    @include transition(opacity 0.25s ease);
    background: $modal-background;
    border-radius: $base-border-radius;
    margin-top: 0;
    margin: auto;
    max-height: 95%;
    overflow: auto;
    padding: $modal-padding / 4 $modal-padding / 3;
    position: relative;
    width: 95%;

    @media (min-width: $screen-sm-min) {
      max-height: 80%;
      padding: $modal-padding / 3 $modal-padding / 2;
      width: 80%;
    }

    @media (min-width: $screen-md-min) {
      width: 60%;
    }

    .jcrop-active {
      text-align: center;
      @include center-block;
    }

    .modal-title {
      color: $base-font-color;
      text-align: center;
      margin: 0 0 0.6em 0;
      text-transform: capitalize;
    }

    p {
      color: $base-font-color;
      line-height: $base-line-height;
    }

    a.cta {
      color: $base-background-color;
      display: inline-block;
      margin-right: 0.5em;
      margin-top: 1em;

      &:last-child {
        padding: 0 2em;
      }
    }

    &.transparent {
      background: transparent;
      .modal-close {

      }
    }

    &.small-modal {
      @media (min-width: $screen-sm-min) {
        max-height: 80%;
        padding: $modal-padding / 3 $modal-padding / 2;
        width: 60%;
      }

      @media (min-width: $screen-md-min) {
        width: 50%;
      }

      @media (min-width: $screen-md-min) {
        width: 40%;
      }
    }
  }

  .modal-state:checked + .modal-fade-screen {
    opacity: 1;
    visibility: visible;
  }

  .modal-state:checked + .modal-fade-screen .modal-inner {
    top: 0.5em;
  }
}

.modal-open {
  overflow: hidden;
  .modal {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

// Based on code by Kasper Mikiewicz
