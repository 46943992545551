.icon-link {
	position: relative;
	padding: 0;
	height: 50px;
	display: block !important;
	background: white;
	line-height: 50px;
	margin: 5px 0;
	border: solid darken($border-color-base, 5) 1px;
	&:hover {
		border-color: darken($border-color-base, 15);
	}
}

.icon-link__icon, .icon-link__btn {
	border: none;
	outline: none;
	position: absolute;
	display: block;
	left: -1px;
	top: -1px;
	padding: 0 15px;
	line-height: 50px !important;
	width: 60px;
	text-align: center;
	background: $brand-secondary;
	color: #fff;
	font-size: 1.8em !important;
	&.facebook {
		background: #3A5795;
	}
}
.icon-link--right .icon-link__icon, .icon-link--right .icon-link__btn {
	right: -1px;
	left: auto;
}

.icon-link__icon-right {
	position: absolute;
	display: block;
	right: -1px;
	top: -1px;
	line-height: 50px !important;
	padding-right: 10px;
}

.icon-link__content, .icon-link__input {
	padding: 0 15px;
	position: absolute;
	left: 55px;
	top: 0;
	color: $brand-primary;
}
.icon-link:hover .icon-link__content {
	color: $text-color;
}
.icon-link__input {
	border: none;
	height: 48px !important;
	width: 100%;
	color: $text-color;
}
.icon-link--right .icon-link__content, .icon-link--right .icon-link__input {
	left: 0;
	right: auto;
}