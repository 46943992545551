.label-switch {  
  $action-color: $brand-primary !default;
  $base-background-color: white !default;
  $switch-padding: 2px;
  $switch-height: 26px;
  $switch-width: ($switch-height * 2);
  $switch-radius: $switch-height;
  $knob-size: $switch-height - ($switch-padding * 2);
  $knob-radius: $switch-height - ($switch-padding * 2);
  $knob-width: $knob-size;
  $switch-background: $base-background-color;
  $switch-border-background: darken($base-background-color, 9%);
  $switch-shadow: 0 1px 2px rgba(0, 0, 0, .2);

  border-radius: $switch-radius;
  cursor: pointer;
  display: block;
  min-height: $switch-height;
  line-height: $switch-height;
  position: relative;
  width: 100%;
  padding: 0 0 0 $switch-width + 10;
  vertical-align: top;
  font-size: .8em;
  @include clearfix;

  input[type="checkbox"] {
    display: none;

    + .checkbox {
      @include transition(all 0.05s ease);
      background: $switch-border-background;
      border-radius: $switch-radius;
      border: none;
      cursor: pointer;
      height: $switch-height;
      margin: 0 10px 0 0;
      padding: 0;
      position: relative;
      width: $switch-width;
      z-index: 0;
      display: inline-block;
      @include position(absolute, 0 0 0 0);

      &:before {
        @include position(absolute, 2px 0 0 0);
        @include transform(scale(1));
        @include transition(all 0.05s ease);
        background: $switch-background;
        border-radius: $switch-radius;
        content: "";
        height: $knob-radius;
        width: $switch-width - ($switch-padding * 2);
        z-index: 1;
      }

      &:after {
        @include position(absolute, 2px 0 0 0);
        @include transition(all 0.05s ease);
        @include size($knob-size);
        background: $switch-background;
        border-radius: $knob-radius;
        box-shadow: $switch-shadow;
        content: "";
        z-index: 2;
      }
    }

    &:checked {
      + .checkbox {
        background: $action-color;

        &:before {
          @include transform(scale(0));
        }

        &:after {
          left: $switch-width - $knob-width - ($switch-padding);
        }
      }
    }
  }
}
