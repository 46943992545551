$base-spacing: 1.5em !default;
$alert-color: #fff6bf !default;
$error-color: #fbe3e4 !default;
$notice-color: #e5edf8 !default;
$success-color: #e6efc2 !default;

@mixin flash($color) {
  background-color: $color;
  color: darken($color, 60%);
  display: block;
  font-weight: 600;
  margin-bottom: 0;
  padding: $base-spacing / 2;
  text-align: center;
  border: solid darken($color, 5) 1px;

  a {
    color: darken($color, 70%);
    text-decoration: underline;

    &:focus,
    &:hover {
      color: darken($color, 90%);
    }
  }
}

.flash-alert {
  @include flash($alert-color);
}

.flash-error {
  @include flash($error-color);
}

.flash-notice {
  @include flash($notice-color);
}

.flash-success {
  @include flash($success-color);
}
