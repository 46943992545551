.progress-bar {
  $base-border-color: gainsboro !default;
  $base-background-color: white !default;
  $base-border-radius: 3px !default;
  $action-color: #477DCA !default;
  $progress-border-color: $base-border-color;
  $progress-border: 1px solid $progress-border-color;
  $progress-meter-border-color: $action-color;
  $progress-meter-border: 1px solid darken($progress-meter-border-color, 15%);
  $progress-meter-color: $progress-meter-border-color;
  $progress-background: darken($base-background-color, 5);
  $progress-animation-duration: 0.7s;
  $progress-height: 30px;

  background-color: $progress-background;
  border: $progress-border;
  box-shadow: inset 0 0 3px 0 rgba(darken($progress-background, 50%), 0.15);
  border-radius: $base-border-radius;
  height: $progress-height;
  margin: 0 auto;
  padding: 2px;
  width: 100%;

  > span.meter {
    @include animation(progress $progress-animation-duration linear infinite);
    box-sizing: border-box;
    background-color: $progress-meter-color;
    @include background-image(linear-gradient(-45deg, rgba(255,255,255, 0.15) 25%,
                                                      transparent 25%,
                                                      transparent 50%,
                                                      rgba(255,255,255, 0.15) 50%,
                                                      rgba(255,255,255, 0.15) 75%,
                                                      transparent 75%));
    background-size: 40px 40px;
    background-repeat: repeat-x;
    //border: $progress-meter-border;
    border-radius: $base-border-radius / 1.5;
    display: block;
    height: 100%;
    width: 0%;
  }
}

@-webkit-keyframes progress {
  0% {
    background-position: 0px 0px;
  }
  100% {
    background-position: 40px 0px;
  }
}

@-moz-keyframes progress {
  0% {
    background-position: 0px 0px;
  }
  100% {
    background-position: 40px 0px;
  }
}

@-ms-keyframes progress {
  0% {
    background-position: 0px 0px;
  }
  100% {
    background-position: 40px 0px;
  }
}

@-o-keyframes progress {
  0% {
    background-position: 0px 0px;
  }
  100% {
    background-position: 40px 0px;
  }
}

@keyframes progress {
  0% {
    background-position: 0px 0px;
  }
  100% {
    background-position: 40px 0px;
  }
}
