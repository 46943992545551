.featured-cards {
  @include display(flex);
  @include flex-wrap(wrap);
  @include justify-content(space-between);
}

.featured-cards__card {
  $base-border-color: gainsboro !default;
  $base-border-radius: 3px !default;
  $base-background-color: white !default;
  $base-spacing: 1.5em !default;
  $action-color: #477DCA !default;
  $dark-gray: #333 !default;
  $base-font-color: $dark-gray !default;
  $card-border-color: $base-border-color;
  $card-border: 1px solid $card-border-color;
  $card-background: lighten($card-border-color, 10%);
  $card-header-background: $card-background;
  $card-margin: 1em;
  $card-image-hover-opacity: 0.7;
  $card-image-hover-color: #FFF;

  @include flex-basis(15em);
  @include flex-grow(1);
  background: #fff;
  @include box-shadow(0 1px 2px rgba(0, 0, 0, .2));
  border-radius: $base-border-radius;
  overflow: hidden;
  border-top: solid $brand-red 4px;
  cursor: pointer;
  margin: 0 $card-margin $base-spacing $card-margin;
  position: relative;
  color: $text-color;

  &:hover, &:focus {
    text-decoration: none;
    outline: none;
  }


  .card__image {
    overflow: hidden;
    height: 150px;
    max-height: 150px;
    background-color: $card-image-hover-color;
    text-align: center;
    border-top-left-radius: $base-border-radius;
    border-top-right-radius: $base-border-radius;
    padding: 15px;
    img {
      height: 100%;
    }
  }

  .card__header {
    @include box-shadow(0 0 2px rgba(0, 0, 0, .25));
    background-color: darken($brand-primary, 10);
    border-top: solid white 1px;
    border-bottom: solid white 1px;
    font-size: 1.2em;
    line-height: 1em;
    font-weight: 300;
    padding: ($base-spacing / 3) ($base-spacing / 2);
    text-align: left;
    color: white;
  }

  .card__copy {
    line-height: 1.5em;
    background: #fff;
    @include pad;
    p {
      margin: 0 0 ($base-spacing / 2);
    }
  }

  &:focus,
  &:hover,
  &:active {
    cursor: pointer;
    @include box-shadow(0 2px 6px 1px rgba(0, 0, 0, .15));
  }

}
