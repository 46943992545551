.footer {
  $base-spacing: 1.5em !default;
  $medium-screen: em(640) !default;
  $large-screen: em(860) !default;
  $footer-background: $brand-dark-blue;
  $footer-color: white;
  $footer-link-color: transparentize($footer-color, 0.6);
  $footer-disclaimer-color: transparentize($footer-color, 0.6);

  background: $footer-background;
  padding: ($base-spacing * 2) $gutter;
  width: 100%;

  .footer-logo {
    margin-bottom: 2em;
    text-align: center;
    img {
      height: 3em;
    }
  } 

  .footer-links {
    @include clearfix;
    margin-bottom: $base-spacing;

    @media (min-width: $screen-sm-min) {
      @include shift(2);
    }
    @media (min-width: $screen-md-min) {
      @include shift(3);
    }
  }

  ul {
    margin-bottom: $base-spacing * 2;
    padding: 0;

    @media (min-width: $screen-sm-min) {
      @include span-columns(4);
      @include omega(4n);
      @include clearfix;
    }
    @media (min-width: $screen-md-min) {
      @include span-columns(3);
      @include omega(3n);
    }
  }

  li {
    line-height: 1.5em;
    list-style: none;
    text-align: center;

    @media (min-width: $screen-sm-min) {
      text-align: left;
    }
  }

  li a {
    color: $footer-link-color;
    text-decoration: none;

    &:focus,
    &:hover {
      color: transparentize($footer-color, 0);
    }
  }

  li h3 {
    color: $footer-color;
    font-size: 1em;
    font-weight: 700;
    margin-bottom: 0.4em;
  }  

  hr {
    border: 1px solid transparentize($footer-disclaimer-color, 0.3);
    margin: 0 auto $base-spacing;
    width: 12em;
  }

  p {
    color: $footer-disclaimer-color;
    font-size: 0.9em;
    line-height: 1.5em;
    margin: auto;
    max-width: 35em;
    text-align: center;
  }
}
