$font-family-sans-serif: "Lato", Helvetica, Arial, sans-serif;

$fa-font-path:        "../../fonts" !default;

$text-color: #1F3B5C;
$body-bg: #F3F4F7;//#F3F3F4;
$grid-columns: 12;
$max-width: 1140px;

$brand-primary:         #107FC9;
$brand-secondary:       #E45635;

$brand-red: tomato;
$brand-green: #72BFBF;
$brand-blue: #92B1E3;
$brand-dark-blue: #134163;
$brand-yellow: #E3D743;
$brand-orange: #F6C05C;
$brand-purple: #6B83A6;

$border-color-base: darken($body-bg, 3);

$basic-plan-color: $brand-blue;
$featured-plan-color: $brand-green;
$premium-plan-color: $brand-orange;

$border-radius-small: 2px;
$border-radius-large: 4px;

$list-group-border-radius: 0;
$list-group-border: white;

$padding-base-vertical: 6px;
$line-height-base: 1.525;
$headings-line-height: 1.35;
$font-size-base: 16px;
$line-height-computed: floor(($font-size-base * $line-height-base));
$input-height-base: ($line-height-computed + ($padding-base-vertical * 2) + 8);
$input-border-radius: 2px;