// SweetAlert
// 2014 (c) - Tristan Edwards
// github.com/t4t5/sweetalert

@import "sweet-alert-animations";

.sweet-overlay {
  background-color: rgba(0, 0, 0, .4);

  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  display: none;
  z-index: $zindex-modal;
}

.sweet-alert {
  $width: 478px;
  $padding: 17px;

  background-color: #fff;
  width: $width;
  padding: $padding;
  border-radius: 5px;
  text-align: center;

  position: fixed;
  left: 50%;
  top: 50%;
  margin-left: -($width / 2 + $padding);
  margin-top: -200px;

  overflow: hidden;
  display: none;
  z-index: 2000;

  @media all and (max-width: $screen-xs-max) {
    width: auto;
    margin-left: 0;
    margin-right: 0;

    left: ($grid-gutter-width / 2);
    right: ($grid-gutter-width / 2);
  }

  .icon {
    width: 80px;
    height: 80px;
    border: 4px solid gray;
    border-radius: 50%;
    margin: 20px auto;
    position: relative;
    box-sizing: content-box;

    &.error {
      border-color: $btn-danger-border;

      .x-mark {
        position: relative;
        display: block;
      }

      .line {
        position: absolute;
        height: 5px;
        width: 47px;
        background-color: $btn-danger-bg;
        display: block;
        top: 37px;
        border-radius: 2px;

        &.left {
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
          left: 17px;
        }
        &.right {
          -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
          right: 16px;
        }
      }
    }
    &.warning {
      border-color: $btn-warning-border;

      .body { // Exclamation mark body
        position: absolute;
        width: 5px;
        height: 47px;
        left: 50%;
        top: 10px;
        border-radius: 2px;
        margin-left: -2px;
        background-color: $btn-warning-bg;
      }
      .dot { // Exclamation mark dot
        position: absolute;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        margin-left: -3px;
        left: 50%;
        bottom: 10px;
        background-color: $btn-warning-bg;
      }
    }
    &.info {
      border-color: $btn-info-border;

      &::before { // i-letter body
        content: "";
        position: absolute;
        width: 5px;
        height: 29px;
        left: 50%;
        bottom: 17px;
        border-radius: 2px;
        margin-left: -2px;
        background-color: $btn-info-bg;
      }
      &::after { // i-letter dot
        content: "";
        position: absolute;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        margin-left: -3px;
        top: 19px;
        background-color: $btn-info-bg;
      }
    }
    &.success {
      border-color: $btn-success-border;

      &::before, &::after { // Emulate moving circular line
        content: '';
        border-radius: 50%;
        position: absolute;
        width: 60px;
        height: 120px;
        background: white;
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
      }
      &::before {
        border-radius: 120px 0 0 120px;
        top: -7px;
        left: -33px;

        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
        -webkit-transform-origin: 60px 60px;
                transform-origin: 60px 60px;
      }
      &::after {
        border-radius: 0 120px 120px 0;
        top: -11px;
        left: 30px;

        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
        -webkit-transform-origin: 0px 60px;
                transform-origin: 0px 60px;
      }

      .placeholder { // Ring
        width: 80px;
        height: 80px;
        border: 4px solid fade($brand-success, 20%);
        border-radius: 50%;
        box-sizing: content-box;

        position: absolute;
        left: -4px;
        top: -4px;
        z-index: 2;
      }

      .fix {  // Hide corners left from animation
        width: 5px;
        height: 90px;
        background-color: #fff;

        position: absolute;
        left: 28px;
        top: 8px;
        z-index: 1;

        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
      }

      .line {
        height: 5px;
        background-color: $btn-success-bg;
        display: block;
        border-radius: 2px;

        position: absolute;
        z-index: 2;

        &.tip {
          width: 25px;

          left: 14px;
          top: 46px;

          -webkit-transform: rotate(45deg);
                  transform: rotate(45deg);
        }
        &.long {
          width: 47px;

          right: 8px;
          top: 38px;

          -webkit-transform: rotate(-45deg);
                  transform: rotate(-45deg);
        }
      }
    }
    &.custom {
      background-size: contain;
      border-radius: 0;
      border: none;
      background-position: center center;
      background-repeat: no-repeat;
    }
  }

  .btn-default {
    @include form-control-focus($btn-default-border);
  }
  .btn-success {
    @include form-control-focus($btn-success-border);
  }
  .btn-info {
    @include form-control-focus($btn-info-border);
  }
  .btn-danger {
    @include form-control-focus($btn-danger-border);
  }
  .btn-warning {
    @include form-control-focus($btn-warning-border);
  }

  button::-moz-focus-inner {
    border: 0;
  }
}
