@mixin grid-item-columns($columns) {
  width: (100% / 12) * $columns;
}

@mixin flex-box-icon($content) {
  content: $content;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  opacity: 0.6;
  font-size: 3em;
}

.flex-boxes {
  $base-background-color: white !default;
  $dark-gray: #333 !default;
  $light-gray: #DDD !default;
  $base-font-color: $dark-gray !default;
  $flex-box-border-color: #fff;
  $flex-box-border: 1px solid $flex-box-border-color;
  $flex-box-background: #fff;
  $flex-box-gutter: 0.4em;
  $flex-box-width: 18em;
  $flex-box-color: $gray-darker;

  @include clearfix;
  @include display(flex);
  @include justify-content(center);
  @include align-items(stretch);
  @include flex-wrap(wrap);
  clear: both;
  text-align: center;
  margin-bottom: 1em;

  .flex-box {
    @include transition (all 0.1s ease-in-out);
    @include flex(2 2 13em);
    @include align-self(stretch);
    background: $flex-box-background;
    display: block;
    margin: $flex-box-gutter;
    padding: 0 1em 1em 1em;
    text-decoration: none;

    &.parties {
      border-top: 6px solid $brand-red;
      color: $brand-red;
      &:before {
        @include flex-box-icon("\f02c");
      }
      &:focus,
      &:hover {
        background-color: transparentize($brand-red, 0.9);
      }
    }

    &.workshops {
      border-top: 6px solid $brand-blue;
      color: $brand-blue;
      &:before {
        @include flex-box-icon("\f1fc");
      }
      &:focus,
      &:hover {
        background-color: transparentize($brand-blue, 0.9);
      }
    }

    &.fairs {
      border-top: 6px solid $brand-yellow;
      color: $brand-yellow;
      &:before {
        @include flex-box-icon("\f185");
      }
      &:focus,
      &:hover {
        background-color: transparentize($brand-yellow, 0.9);
      }
    }

    &.conferences {
      border-top: 6px solid $brand-orange;
      color: $brand-orange;
      &:before {
        @include flex-box-icon("\f145");
      }
      &:focus,
      &:hover {
        background-color: transparentize($brand-orange, 0.9);
      }
    }

    &.training-seminars {
      border-top: 6px solid $brand-purple;
      color: $brand-purple;
      &:before {
        @include flex-box-icon("\f19d");
      }
      &:focus,
      &:hover {
        background-color: transparentize($brand-purple, 0.9);
      }
    }

    .flex-box__icon {
      opacity: 0.9;
      font-size: 3em;
      padding: .5em;
    }

    .flex-title {
      color: $flex-box-color;
      font-weight: 300;
      display: block;
      line-height: 1.2;
      margin-bottom: 0.5em;
      margin-top: 0;
    }

    p {
      color: transparentize($flex-box-color, 0.1);
      line-height: 1.5em;
      margin: auto;
    }
  }

  .flex-box-big {
    @include flex(1 1 40em);
  }
}