.button-group {  
  $base-border-color: gainsboro !default;
  $base-border-radius: 3px !default;
  $base-line-height: 1.5em !default;
  $base-spacing: 1.5em !default;
  $base-font-size: 1em !default;
  $base-background-color: white !default;
  $action-color: $brand-primary !default;
  $dark-gray: $text-color !default;
  $large-screen: em(860) !default;
  $base-font-color: $dark-gray !default;
  $button-group-background: $base-background-color;
  $button-group-color: $brand-secondary;
  $button-group-icon-color: $brand-primary;
  $button-group-border: 1px solid silver;
  $button-group-inner-border: 1px solid lighten(silver, 18%);
  $button-group-background-checked: $action-color;
  $button-group-color-checked: white;
  $button-group-border-checked: darken($button-group-background-checked, 15%);

  @include clearfix;

  input {
    display: none;
  }

  label, a {
    margin-bottom: 0;
    display: block !important;
    padding: 0 !important;

    &:focus,
    &:hover {
      text-decoration: none;
    }

    .button-group__item {
      background: $button-group-background;
      color: $button-group-color;
      cursor: pointer;
      font-size: $base-font-size;
      font-weight: normal;
      line-height: 1;
      padding: 0.75em 1em;
      width: 100%;
      display: block;
      text-align: center;
      text-shadow: 0 0 1px rgba(0, 0, 0, .15);
      border: solid darken($body-bg, 10) 1px;
      border-bottom: none;
      @media (min-width: $screen-sm-min) {
        border-right: none;
        border-bottom: solid darken($body-bg, 10) 1px;
      }

      &:focus,
      &:hover {
        background-color: darken($button-group-background, 3%);
      }
    }

    .item__icon {
      color: $button-group-icon-color;
      @include responsive-font-size($base-font-size * 3);
      line-height: $base-line-height;
      display: none;
      text-shadow: 0px 1px 1px rgba(0, 0, 0, .25);
      @media (min-width: $screen-sm-min) {
        display: block;
      }
    }

    &:last-child .button-group__item {
      border-bottom: solid darken($body-bg, 10) 1px;
      @media (min-width: $screen-sm-min) {
        border-right: solid darken($body-bg, 10) 1px;
      }
    }

    input:checked + .button-group__item {
      @extend .bg-primary;
      color: $button-group-color-checked;
      .item__icon {
        color: $button-group-color-checked;
      }
      .item__title {
        color: $button-group-color-checked;
        small {
          color: $brand-orange;
        }
      }
    }
  }
}
