.hero {  
  $base-border-radius: 3px !default;
  $action-color: #477DCA !default;
  $large-screen: em(860) !default;
  $hero-background: $brand-primary;
  $hero-color: white;
  $gradient-angle: 10deg;
  $hero-image: '../images/hero-swirl-design.png';
  $title-font-size: $font-size-h1 * 1.1;

  background-color: $hero-background;
  padding: 1em 0 1.5em;
  position: relative;
  @media (min-width: $screen-sm-min) {
    padding: 1.5em 0 2em;
  }
  &.hero--peeking-bottom {
    padding: 2em 0 5em;
    @media (min-width: $screen-sm-min) {
      padding: 3em 0 6em;
    }
  } 

  .hero__logo {
    img {
      @include box-shadow(0 1px 2px rgba(0, 0, 0, .25));
      @include center-block;
      height: 6em;
      margin-bottom: 1em;
      padding: 5px;
      background: white;
    }
    &.hero__logo--large {
      img {
        height: 9em;
        @media (min-width: $screen-sm-min) {
          height: 10em;
        }
      }
    }

    &.hero__logo--peeking {
      position: absolute;
      bottom: -3em;
      left: 50%;
      margin-left: -4em;
    }
  }

  .hero__icon {
    @include responsive-font-size($title-font-size);
    margin-bottom: .5em;
    @include box-shadow(0 1px 2px rgba(0, 0, 0, .25));
  }

  .hero__copy {
    color: $hero-color;
    text-align: center;
    &.hero__copy--centered {
      text-align: center !important;
    }
    @media (min-width: $screen-sm-min) {
      text-align: left;
    }
    @extend .pad-horizontal;
    h1 {
      margin: 0;
      font-weight: 300;
      text-shadow: 0 2px 2px rgba(0, 0, 0, .2);
      letter-spacing: 0;
      @include responsive-font-size($title-font-size);
    }
    
    .search-bar {
      .search-bar__btn {
        color: $hero-color;
        top: 0;
      } 
      input {
        border: solid lighten($hero-background, 30) 1px;
        @include box-shadow(0 0 2px rgba(0, 0, 0, .25));
        background: transparentize($hero-background, .5);
        color: $hero-color;
        &:focus {
          @include box-shadow(0 0 6px rgba(0, 0, 0, .35));
        }
        @include placeholder {
          color: $hero-color;
          opacity: .6;
        }
      }
    }

    p {
      font-weight: 200;
      line-height: 1.4em;
      margin: .75em auto 0; 
      @media (min-width: $screen-md-min) {
        max-width: 80%;
      }
      a {
        color: transparentize($hero-color, .3);
        text-decoration: underline;
      }
    }
  }

}
