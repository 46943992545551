.sliding-panel-content {  
  $action-color: $brand-primary !default;
  $dark-gray: #333 !default;
  $sliding-panel-border-color: $dark-gray;
  $sliding-panel-background: lighten($sliding-panel-border-color, 5%);
  $sliding-panel-color: #fff;
  $sliding-panel-border: 1px solid $sliding-panel-border-color;
  $sliding-panel-background-hover: $action-color;
  $sliding-panel-color-hover: #fff;
  $sliding-panel-background-focus: lighten($sliding-panel-background, 5%);

  @media (max-width: $screen-sm-max) {
    @include position(fixed, 0px auto 0px 0px);
    @include size(220px 100%);
    @include transform(translateX(-220px));
    @include transition(all 0.15s linear);
    background: $sliding-panel-background;
    z-index: 999999;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    li a {
      border-bottom: $sliding-panel-border;
      color: $sliding-panel-color;
      font-weight: bold;
      padding: 0 1em;
      display: block;
      
      &:focus {
        background-color: $sliding-panel-background-focus;
      }

      &:hover {
        background-color: $sliding-panel-background-hover;
        color: $sliding-panel-color-hover;
      }
    }

    &.is-visible {
      @include transform(translateX(0));
    }
  }

  ul {
    padding: 0;
    margin: 0;
  }

  li {
    list-style: none;
  }

  li a {
    text-decoration: none;
  }

}

.sliding-panel-fade-screen {
  @include position(fixed, 0px 0px 0px 0px);
  @include transition;
  background: black;
  opacity: 0;
  visibility: hidden;
  z-index: 999998;

  &.is-visible {
    opacity: 0.4;
    visibility: visible;
  }
}

.sliding-panel-button {
  color: transparentize(#333, 0.3);
  display: block;
  float: right;
  font-weight: 700;
  height: 65px;
  margin: 0;
  text-transform: uppercase;
  border: none;
  border-bottom: solid $border-color-base 1px;
  background-color: white;
  padding: 0 16px;
  cursor: pointer;
  position: relative;
  outline: none;
  @media (min-width: $screen-md-min) {
    display: none !important;
  }
  &:hover {
    text-decoration: none;
  }
}

// Based on code by Diego Eis
