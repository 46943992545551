#map-canvas {
	min-height: 270px;
	@media (min-width: $screen-md-min) {
		min-height: 325px;
	}
  height: 100%;
	width: 100%;
}

#map-canvas img { 
  max-width: none; // to prevent UI control bug
}

#map-canvas .content {
  padding: 0 0.5em 1em 0.5em;
}

#map-canvas .firstHeading {
  font-size: 1em;
  line-height: 1em;
  font-weight: bold;
  margin: 0;
}

#map-canvas #bodyContent p {
  font-size: 1em;
  margin: 0;
}

.map-overlay-container {
  position: relative;
  .map-overlay {
    width: 100%;
    position: absolute;
    background: rgba(255, 255, 255, .8);
    bottom: 0;
    left: 0;
    &.map-overlay--top {
      top: 0;
      bottom: auto;
    }
  }
}