.accordion-tabs {
  $base-border-color: darken($body-bg, 10);
  $base-border-radius: 3px !default;
  $base-background-color: white !default;
  $base-spacing: 1.5em !default;
  $action-color: $brand-primary !default;
  $dark-gray: #333 !default;
  $light-gray: #DDD !default;
  $medium-screen: em(760) !default;
  $tab-border: 1px solid $base-border-color;
  $tab-content-background: white;
  $tab-active-background: $tab-content-background;
  $tab-inactive-color: $base-background-color;
  $tab-inactive-hover-color: darken($light-gray, 5);
  $tab-mode: $medium-screen;

  @include clearfix;
  line-height: 1.5;
  margin-bottom: $base-spacing;
  padding: 0;

  @include media(max-width $tab-mode) {
    border-radius: $base-border-radius;
    border: $tab-border;
  }

  .tab-header-and-content {
    list-style: none;

    @include media($tab-mode) {
      display: inline;
    }

    &:first-child .tab-link {
      border-top-left-radius: $base-border-radius;
      border-top-right-radius: $base-border-radius;

      @include media(max-width $tab-mode) {
        border-top: 0;
      }
    }

    &:last-child .tab-link {
      @include media(max-width $tab-mode) {
        border-bottom-left-radius: $base-border-radius;
        border-bottom-right-radius: $base-border-radius;
      }
    }
  }

  .tab-link {
    background-color: $tab-inactive-color;
    border-top: $tab-border;
    color: $text-color;
    display: block;
    font-weight: bold;
    padding: $base-spacing/2 $gutter/2;
    text-decoration: none;

    @include media($tab-mode) {
      display: inline-block;
      border-top-left-radius: $base-border-radius;
      border-top-right-radius: $base-border-radius;
      border-top: 0;
    }

    &:hover {
      color: $action-color;
    }

    &:focus {
      outline: none;
    }

    &.is-active {
      background-color: $tab-active-background;

      @include media($tab-mode) {
        background-color: $tab-active-background;
        border: $tab-border;
        border-bottom-color: $tab-active-background;
        margin-bottom: -1px;
      }
    }
  }

  .tab-content {
    background: $tab-content-background;
    display: none;
    @include pad;
    width: 100%;

    @include media($tab-mode) {
      border-bottom-left-radius: $base-border-radius;
      border-bottom-right-radius: $base-border-radius;
      border-top-right-radius: $base-border-radius;
      border: $tab-border;
      float: left;
    }
  }
}

.vertical-tabs-container {
  $base-border-color: gainsboro !default;
  $base-border-radius: 3px !default;
  $base-background-color: white !default;
  $base-line-height: 1.5em !default;
  $base-spacing: 1.5em !default;
  $action-color: #477DCA !default;
  $dark-gray: #333 !default;
  $medium-screen: em(640) !default;
  $base-font-color: $dark-gray !default;
  $tab-border-color: $base-border-color;
  $tab-border: 1px solid $tab-border-color;
  $tab-border-radius: $base-border-radius;
  $tab-content-background: lighten($tab-border-color, 10%);
  $tab-active-color: $tab-content-background;
  $tab-inactive-color: $base-background-color;
  $vertical-tabs-height: em(300);
  $vertical-tab-mode: $medium-screen;

  @include clearfix;
  border-radius: $tab-border-radius;
  border: none;
  margin-bottom: $base-spacing;
  overflow: hidden;
  @include box-shadow(0 1px 2px rgba(0, 0, 0, .25));

  li {
    list-style: none;
  }

  a {
    color: $action-color;
    text-decoration: none;
  }

  .vertical-tabs {
    display: none;

    @include media($vertical-tab-mode) {
      background-color: $tab-inactive-color;
      display: inline;
      float: left;
      height: $vertical-tabs-height;
      width: 20%;
    }
  }

  .vertical-tab {
    @include media($vertical-tab-mode) {
      border-bottom: $tab-border;
      display: block;
      font-weight: bold;
      margin-right: -1px;
      padding: ($base-spacing / 2) ($gutter / 2);

      &.is-active {
        background-color: $tab-content-background;
        margin-right: -1px;
      }
    }
  }

  a.vertical-tab-accordion-heading,
  a.vertical-tab {
    color: $text-color;
  }

  .vertical-tab:focus {
    outline: none; 
  }

  .vertical-tab-content-container {
    display: block;
    margin: 0 auto;

    & a:focus { 
      outline: none; 
    }

    @include media($vertical-tab-mode) {
      @include size(80% $vertical-tabs-height);
      background-color: $tab-content-background;
      display: inline-block;
    }
  }

  .vertical-tab-content {
    background-color: $tab-content-background;
    padding: $base-spacing $gutter;

    p {
      color: $base-font-color;
      line-height: $base-line-height;
    }

    @include media($vertical-tab-mode) {
      border: none;
      display: none;
    }
  }

  .vertical-tab-accordion-heading {
    background-color: $tab-inactive-color;
    border-top: $tab-border;
    cursor: pointer;
    display: block;
    font-weight: bold;
    padding: $base-spacing / 2 $gutter / 2;

    &:focus,
    &:hover {
      color: $action-color;
    }

    &:first-child {
      border-top: none;
    }

    &.is-active {
      background: $tab-active-color;
      border-bottom: none;
    }

    @include media($vertical-tab-mode) {
      display: none;
    }
  }
}
