@import "variables";

@import "bootstrap";
@import "../../../vendor/bower_components/fontawesome/scss/font-awesome";
@import "sweet-alert/sweet-alert";

@import "bourbon/bourbon";
@import "neat/neat";

@import "mixins";
@import "components/header";
@import "components/footer";
@import "components/hero";
@import "components/search-bar";
@import "components/alerts";
@import "components/select2";
@import "components/card";
@import "components/icon-links";
@import "components/flex-boxes";
@import "components/maps";
@import "components/tabs";
@import "components/tables";
@import "components/locations";
@import "components/list-items";
@import "components/modal";
@import "components/sliding-panel";
@import "components/datepicker";
@import "components/bullets";
@import "components/loading";
@import "components/progress-bar";
@import "components/button-group";
@import "components/social-networks";
@import "components/switch";
@import "components/badge";
@import "components/featured-cards";
@import "components/companies";
@import "modal/main";
@import "components/expander";
@import "components/stats";
@import "components/raty";

.company-heading {
	position: relative;
	img {
		@extend .card;
		margin: 0;
		width: 125px;
		float: left;
		@media (min-width: $screen-md-min) {
			width: 175px;
			position: absolute;
			left: 0;
			z-index: 100;
		}
	}
	h1 {
		margin: 0;
		text-align: center;
		@extend .h2;
		@media (min-width: $screen-sm-min) {
			text-align: left;
			padding-left: 145px;
		}
		@media (min-width: $screen-md-min) {
			padding-left: 195px;
		}
	}
}

dl.company-specs {
	dd {
		margin-bottom: .5em;
	}
	@media (min-width: $screen-sm-min) {
	    dt {
			width: 40%;
		}
		dd {
			margin-left: 45%;
		}
	 }
}

.email-subscribe {
	background: $body-bg;
	position: relative;
	.close {
		position: absolute;
		right: .25em;
		top: 0;
		font-size: 2em;
		padding: 0;
		color: darken($body-bg, 20%);
		&:hover {
			text-decoration: none;
		}
	}
}

.mb-0 {
	margin-bottom: 0;
}

.mt-0 {
	margin-top: 0;
}

.py-3 {
	padding-top: 1em;
	padding-bottom: 1em;
}

@media (max-width: $screen-xs-max) {
  .container {
    padding:5px !important;
    margin:0 !important;
    overflow-x: hidden;
  }

  

  .navbar-fixed-top, .navbar-fixed-bottom, .navbar-static-top {
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-bottom:0 !important;
    overflow-x: hidden;
  }

}

.xs-squeeze {
	@media (max-width: $screen-xs-max) {
		padding:5px !important;
	    margin:0 !important;
	    overflow-x: hidden;
	}
}

.xs-block {
	@media (max-width: $screen-xs-max) {
		display: block !important;
		float: none !important;
		margin-top: .5em;
	}
}

.xs-lead {
	@media (max-width: $screen-xs-max) {
		font-size: 18px;
	}
}

.contact-modal {
	position: relative;
	background: #FFF;
	width: auto;
	max-width: 650px;
	margin: 20px auto;
}

.border-top {
	border-top: solid darken($body-bg, 5) 1px !important;
}

.navbar.affix {
	@extend .navbar-fixed-top;
}

.scrollup {
    width: 40px;
    position: fixed;
    bottom: 50px;
    right: 10px;
    @media (min-width: $screen-sm-min) {
		right: 50px;
	}
    display: none;
    background-color: #000;
    opacity: .7;
    font-size: 1.6em;
    line-height: 40px;
    text-align: center;
    color: #fff;
    &:active, &:focus {
    	outline: none;
    	color: #fff;
    }
}

.home-summary {
	max-height: 200px;
	@media (min-width: $screen-sm-min) {
		max-height: 215px;
	}
	@media (min-width: $screen-md-min) {
		max-height: 220px;
	}
	@media (min-width: $screen-lg-min) {
		max-height: 230px;
	}
}

.trending-companies {
	@include center-block;
	
	p {
		margin-bottom: 0;
	}
	a {
		font-weight: bold;
		padding-left: 10px;
		padding-right: 10px;
		border-right: solid #fff 1px;
		border-bottom: none !important;
		&:last-child {
			border-right: none;
		}
		&:first-child {
			padding-left: 0;
		}
	}
}

#companies-to-compare {
	img {
		width: 25%;
		max-height: 200px;
		display: inline-block;
	}
}

.company-comparison {
	width: 100% !important;
	th {
		padding: 0 !important;
		text-align: center;
		width: 20% !important;
		img {
			width: 100%;	
		}
	}
	td {
		text-align: center;
		@include pad;
	}
	.consultants {
		&:hover {
			> td {
				background: transparent !important;
			}
		}
		td {
			vertical-align: top;
		}
	}
}

.btn-flat {
	border-radius: 0 !important;
	box-shadow: none !important;
}

.responsive-table {
	width: 100%;
	overflow-x: scroll;
	overflow-y: hidden;
}

h1, .h1 { 
	@include responsive-font-size($font-size-h1 * 1.2);
	letter-spacing: 1px;
	font-weight: 300;
}
h2, .h2 { 
	@include responsive-font-size($font-size-h2 * 1.2);
	letter-spacing: 1px;
	font-weight: 300;
}
h3, .h3 { 
	@include responsive-font-size($font-size-h3 * 1.2);
	letter-spacing: 1px;
	font-weight: 300;
}
h4, .h4 {
	letter-spacing: .5px;
}

.btn-link {
	cursor: pointer !important;
}

.content-block {
	-webkit-user-select: none;  
	-moz-user-select: none;     
	-ms-user-select: none;      
	user-select: none;
	font-size: 18px;
}

.item__description {
	-webkit-user-select: none;  
	-moz-user-select: none;     
	-ms-user-select: none;      
	user-select: none;
	font-size: 17px;
}

.comparison-image {
	@include center-block;
	height: 150px;
}

.search-tools {
	padding: 0;
	display: table;
	width: 100%;
	.search-tool {
		display: table-cell;
		border-left: solid darken($body-bg, 10) 1px;
		text-transform: uppercase;
		line-height: 45px;
		text-align: center;
		height: 45px;
		margin: 0;
		width: 1%;
		a {
			display: block;
			text-decoration: none;
			outline: none;
			letter-spacing: 1px;
			font-weight: 700;
			span {
				font-weight: 300;
			}
			&.active {
				background: $brand-secondary;
				color: white;
				position: relative;
				&:after {
				    content:'';
				    position: absolute;
				    top: 100%;
				    left: 50%;
				    margin-left: -10px;
				    width: 0;
				    height: 0;
				    border-top: solid 10px $brand-secondary;
				    border-left: solid 10px transparent;
				    border-right: solid 10px transparent;
				}
			}
		}
	}
}

.search-results-container {
	display: none;
	&.active {
		display: block;
	}
	.search-results {
		.result {
			display: none;
		}
	}
}

.marketing-overlay {
	@include position(fixed, 0);
    background-color: rgba(#000, 0.85);
    z-index: 1040;
    padding-top: 4em;
    display: none;

    .overlay-inner {
	    margin-top: 0;
	    margin: auto;
	    max-height: 95%;
	    overflow-x: hidden;
    	overflow-y: auto;
	    position: relative;
	    width: 95%;
	    color: #fff !important;
	    font-weight: 700 !important;

	    @media (min-width: $screen-sm-min) {
	      width: 90%;
	    }

	    @media (min-width: $screen-md-min) {
	      width: 80%;
	    }

	    @media (min-width: $screen-lg-min) {
	      width: 70%;
	    }
	}

	.overlay-close {
	    @include position(absolute, 2em 2em null null);
	    @include size(2em);
	    cursor: pointer;
	    
	    &:after,
	    &:before {
	      @include position(absolute, 3px 3px 0 50%);
	      @include transform(rotate(45deg));
	      @include size(0.15em 2em);
	      background: #fff;
	      content: '';
	      display: block;
	      margin: -3px 0 0 -1px;
	    }

	    &:hover:after,
	    &:hover:before {
	      background: darken(#fff, 10%);
	    }

	    &:before {
	      @include transform(rotate(-45deg));
	    }
	  }
}

.content-block img {
	@include img-responsive;
}

.cancel-on-png, .cancel-off-png, .star-on-png, .star-off-png, .star-half-png {
			font-size: 1.5em !important;
			color: $brand-orange;
		}

.user-review {
	@include pad(1% 0);
	border-top: solid rgba(0, 0, 0, .08) 1px;
	@include clearfix;
	.review__rating {
		float: right;
	}
	.review__name {
		
	}
}

.justified-nav {
	display: table;
	width: 100%;
	background: white;
	a {
		display: table-cell;
		width: 1%;
		text-align: center;
		padding: 1%;
		color: $text-color;
		@include responsive-font-size(20px);
		border-bottom: solid $border-color-base 1px;
		border-top: solid $border-color-base 1px;
		&:hover, &.active, &:active, &:focus {
			text-decoration: none;
			background: $body-bg;
			border-bottom: none;
			border-top: none;
			outline: none;
		}
	}
	a + a {
		border-left: solid $border-color-base 1px;
	}
	.nav__icon {
		display: block;
		font-size: 1.8em;
	}
}

.icon__parties {
	@include fa-icon;
	@extend .fa-home;
	color: $brand-red;
}

.icon__workshops {
	@include fa-icon;
	@extend .fa-paint-brush;
	color: $brand-blue;
}

.icon__fairs {
	@include fa-icon;
	@extend .fa-sun-o;
	color: $brand-yellow;
}

.icon__conferences {
	@include fa-icon;
	@extend .fa-ticket;
	color: $brand-orange;
}

.icon__training-seminars {
	@include fa-icon;
	@extend .fa-graduation-cap;
	color: $brand-purple;
}

blockquote {
	@include responsive-font-size(20px, 18px);
}

label {
	color: $text-color;
}

section {
	margin: 0;
	@extend .pad;
	@include clearfix;
}

.visible-link {
	cursor: pointer;
	text-decoration: underline;
}

.hover-border {
	border: solid $border-color-base 1px;
	&.br {
		border-radius: 3px;
	}
	&:hover {
		border-color: darken($border-color-base, 10);
	}
}

.video-wrapper {
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%; // For ratio 16:9. 75% if ratio is 4:3
  position: relative;

  embed,
  object,
  iframe {
    @include position(absolute, 0px null null 0px);
    @include size(100%);
  }
}


.text-white, .text-free {
	color: white !important;
}

.list-group {
	border: solid rgba(0, 0, 0, .1) 1px;
	.list-group__item {
		position: relative;
		padding: 0;
		display: block !important;
		background: white;
		height: 42px;
		line-height: 42px;
		@include pad(0 4%);
		&:hover, &:focus {
			background: darken(white, 5);
			text-decoration: none;
		}
	}

	.list-group__item + .list-group__item {
		border-top: solid $border-color-base 1px;
	}

	.item__icon {
		height: 42px;
		line-height: 42px;
		position: absolute;
		display: block;
		left: 0;
		top: 0;
		&.item__icon--right {
			right: 0;
			left: auto;
			top: 0;
			padding-right: 10px;
		}
	}
}

.tab-content {
	.tab-pane {
		display: none;
		&.active {
			display: block;
		}
	}
}

.negative-margin {
	margin: 0 -15px;
}

.avatar {
	height: 10em;
	margin: 0 auto;
	&.round {
		border: solid white 3px;
		border-radius: 100%;
		background: white;
		@include box-shadow(0 1px 2px rgba(0, 0, 0, .25));
	}
	@media (min-width: $screen-lg-min) {
		height: 12em;
	}
}

.peeking-photo {
	position: relative;
	padding-bottom: 9em;
	margin-bottom: 3em;
	.peeking-photo__photo {
		width: 100%;
		.content-left {
			position: absolute;
			left: 50%;
			margin-left: 10em;
			bottom: -1em;
		}
		img {
			position: absolute;
			bottom: -3em;
			left: 50%;
			height: 10em;
			margin: 0 0 0 -5em;
			border: solid white 3px;
			border-radius: 100%;
			@extend .card;
		}
	}
	@media (min-width: $screen-lg-min) {
		padding-bottom: 11em;
		.peeking-photo__photo {
			img {
				height: 12em;
				margin: 0 0 0 -6em;
			}
		}
	}
}

nav.basic-tabs {
	line-height: 60px;
	text-align: center;
	background: white;
	overflow-y: scroll;
	width: 100%;
	border-bottom: solid darken($body-bg, 10) 1px;
	a {
		display: inline-block;
		text-transform: uppercase;
		padding: 0 .5em;
		color: transparentize($brand-primary, .35);
		@media (min-width: $screen-sm-min) {
			padding: 0 1.5em;
		}
		&:hover, &:focus, &.active {
			color: darken($brand-primary, 5%);
			text-decoration: none;
			outline: none;
		}
	}
}

.js-fade-element-hide {
  opacity: 0;
}

.js-fade-element-show {
  @include transition (all 0.4s ease-in-out);
  opacity: 1;
}

.lead {
	@include responsive-font-size(1.5em);
	font-weight: 300;
	text-shadow: 0 .5px 1px rgba(0, 0, 0, .25);
	strong, a, em {
		font-weight: 400;
	}
}

ul.listing-photos {
	@include outer-container;
	list-style: none;
	padding: 0;
	margin: 0;
	li {
		margin-top: 2%;
		padding: 5px;
		border: solid darken($body-bg, 5) 1px;
		background: $body-bg;
		text-align: center;
		cursor: pointer;

		@media (max-width: $screen-md-max) {
			@include span-columns(3);
			@include omega(4n);
		}
		@media (min-width: $screen-lg-min) {
			@include span-columns(2);
			@include omega(6n);
		}
		&.add-more {
			cursor: pointer;
			label {
				display: block;
				cursor: pointer;
				text-align: center;
			}
			&:hover {
				border-color: darken($body-bg, 15);
			}
			.add-more__icon {
				display: block;
				font-size: 3em;
				@include center-block;
				padding: 10px 0;
			}
		}
		.handle {
			font-size: 1.2em;
			padding-bottom: 5px;
		}
		&.sortable-ghost {
			opacity: .4;
		}
	}
}

.readmore-js-toggle {
    padding: .25em 0;
    font-size: .9em;
    text-align: center;
    margin: .5em 0 0;
    border: solid lighten($brand-primary, 10%) 1px;
    border-top: none;
    background: darken($brand-primary, 5%);
    color: #fff !important;
    &:hover {
    	color: #fff;
    }
}


.crop-canvas {
	@include center-block;
	width: 300px;
	height: 300px;
	overflow: hidden;
}

.text-secondary {
	color: $brand-secondary;
}

.bg-even {
	background: white;
	border-bottom: solid darken($body-bg, 10) 1px;
}

.bg-odd {
	background: $body-bg;
	border-bottom: solid darken($body-bg, 10) 1px;
}

.bg-primary {
	border-bottom: solid darken($brand-primary, 10) 1px;
	background-color: $brand-primary;
	.dropdown-menu a {
		color: $text-color !important;
	}
	p a, ul a, ol a {
		color: white;
		text-decoration: none;
		border-bottom: dotted 1px white;
		&:hover {
			border-bottom-style: solid;
		}
	}
}

.list-group-item + .list-group-item {
	border-top-color: $body-bg;
}

.collapse {
	display: none;
	&.in {
		display: block;
	}
}

.text-premium {
	color: $premium-plan-color !important;
}

.text-featured {
	color: $featured-plan-color !important;
}

.text-basic {
	color: $basic-plan-color !important;
}

.form-horizontal {
	.form-horizontal-reset {
		.form-group {
			margin-left: 0;
			margin-right: 0;
		}
	}
}

.section-header {
	line-height: 2;
	color: $brand-primary;
	font-weight: 200;
	text-transform: uppercase;
	border-bottom: dotted $brand-primary 2px;
}

.pad {
	@include pad;
}

.pad-lg {
	@include pad(5% 10%);
}

.pad-sm {
	@include pad(1% 2%);
}

.bg-border {
	background: $border-color-base;
}

.border-white {
	border-color: #fff !important;
}

.pad-horizontal {
	padding-left: $grid-gutter-width / 2;
	padding-right: $grid-gutter-width / 2;
}

.pad-vertical {
	padding: 15px 0;
}

.no-margin {
	margin: 0 !important;
}

.no-margin-top {
	margin-top: 0 !important;
}

.no-margin-bottom {
	margin-bottom: 0 !important;
}

.no-margin-vertical {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
}

.no-margin-horizontal {
	margin-left: 0 !important;
	margin-right: 0 !important;
}

.no-padding {
	padding: 0 !important;
}

.no-padding-right {
	padding-right: 0 !important;
}

.no-padding-left {
	padding-left: 0 !important;
}

.no-padding-vertical {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}

.separate-vertical {
	margin-top: .5em;
	margin-bottom: .5em;
}

.option-block {
	display: block !important;
    padding: 0 !important;
	.option-block__option {
		position: relative;
		display: block;
		font-weight: normal;
		@extend .pad;
		@extend .hover-border;
		@extend .separate-vertical;
		background: white;
		&:hover {
			text-decoration: none;
			cursor: pointer;
			background-color: darken(white, 3);
			.option-block__tools {
				display: block;
			}
		}
	}
	input {
		display: none;
	}
	input:checked + .option-block__option {
		background-color: $brand-primary;
		border-color: darken($brand-primary, 10);
		color: white;
		.option-block__tools {
			display: block;
		}
	}
	.option-block__tools {
		display: none;
		position: absolute;
		right: 5px;
		top: 5px;
		a, label, button {
			outline: none;
			border: none;
			cursor: pointer;
			display: inline-block;
			background: rgba(0, 0, 0, .3);
			color: white;
			border-radius: 2px;
			@include size(26px);
			line-height: 26px;
			padding: 0;
			text-align: center !important;
			&:hover {
				background: black;
			}
		}
	}
}

.no-border {
	border: none !important;
}

.no-border-top {
	border-top: none !important;
}

.btn {
	@include box-shadow(0 1px 2px rgba(0, 0, 0, .2));
	&.btn-lg {
		font-weight: 300;
	}
	&.wide {
		padding-left: 3em;
		padding-right: 3em;
	}
	&.wider {
		padding-left: 4em;
		padding-right: 4em;
	}
	&.btn-secondary {
		@include button-variant(white, $brand-secondary, darken($brand-secondary, 10));
	}

	&.btn-round {
		border-radius: 30px;
	}

	&.btn-inverse {
		border-width: 2px;
	}

	&.btn-primary {
		&.btn-inverse {
			@include button-variant($brand-primary, white, $brand-primary);
			@include box-shadow(none);
			&:focus, &:hover {
				background: $brand-primary !important;
				border-color: $brand-primary !important;
				color: white !important;
				outline: none;
			}
		}
		&.light-border {
			border-color: lighten($brand-primary, 25%);
		}
	}
	&.btn-secondary {
		&.btn-inverse {
			@include button-variant($brand-secondary, white, $brand-secondary);
			@include box-shadow(none);
			&:focus, &:hover {
				background: $brand-secondary !important;
				border-color: $brand-secondary !important;
				color: white !important;
				outline: none;
			}
		}
	}
	&.btn-danger {
		&.btn-inverse {
			@include button-variant($brand-danger, white, $brand-danger);
			@include box-shadow(none);
			&:focus, &:hover {
				background: $brand-danger !important;
				border-color: $brand-danger !important;
				color: white !important;
				outline: none;
			}
		}
	}
}

.form-control {
	@include transition(border-color ease-in-out .05s, box-shadow ease-in-out .05s);
}
.form-control:focus {
	@include box-shadow(0 0 3px rgba(0, 0, 0, .2));
}

.display-block {
	display: block !important;
}

.display-inline {
	display: inline !important;
}

.margin-top-small {
	margin-top: .5em !important;
}

.padding-sides-small {
	padding-left: .5em;
	padding-right: .5em;
}

.text-center-xs {
	@media (max-width: $screen-xs-max) {
		text-align: center;
	}
}

.top-offset {
	margin-top: 1em;
}

.heading-top-offset {
	margin-top: 20%;
	&.heading-top-offset--small {
		margin-top: 0;
		@media (min-width: $screen-sm) {
			margin-top: 20%;
		}
	}

	&.heading-top-offset--medium {
		margin-top: 0;
		@media (min-width: $screen-md) {
			margin-top: 20%;
		}
	}
}

.pac-container {
	.pac-item, .pac-item-query {
		font-size: $font-size-base;
		@include pad(5px);
		cursor: pointer;
	}
	.pac-item:hover {
		background: $brand-primary;
		color: white !important;
		.pac-item-query, .pac-icon {
			color: white !important;
		}
	}
}

.tt-input {
	margin: 0;
}
.twitter-typeahead { width: 100%; color: $text-color !important;}
.twitter-typeahead .category {
	background: $body-bg;
	padding: 10px 0;
	margin: 0;
}
.twitter-typeahead .tt-hint { color: #999; }
.twitter-typeahead .tt-dropdown-menu { width: 100%; padding: 0; @extend .card; }
.twitter-typeahead .tt-dropdown-menu .tt-suggestion { 
	text-align: left; 
	font-size: 18px; 
	line-height: 24px; 
	a {
		@include pad;
		display: block;
		img {
			margin-right: 10px;
		}
	}
}
.twitter-typeahead .tt-dropdown-menu .tt-suggestion.tt-cursor { 
	color: #fff !important; 
	background-color: $brand-primary; 
	a {
		color: #fff !important; 
	}
}
.twitter-typeahead .tt-dropdown-menu .tt-suggestion p { margin: 0; }
.twitter-typeahead .tt-dropdown-menu .tt-suggestion a { color: #000; text-decoration: none; }
.twitter-typeahead .tt-dropdown-menu .tt-suggestion em { font-weight: bold; font-style: normal; }

