header#layout-header {
  $base-border-color: darken($body-bg, 10);
  $dark-gray: #333 !default;
  $navigation-background: white;
  $navigation-color: transparentize($dark-gray, 0.3);
  $navigation-color-hover: $dark-gray;
  $navigation-height: 65px;
  $navigation-active-link-color: $brand-primary;
  
  background-color: $navigation-background;
  border-bottom: solid $border-color-base 1px;
  height: $navigation-height;
  width: 100%;
  @include clearfix;
  position: relative;

  .logo {
    float: left;
    height: $navigation-height;
    line-height: $navigation-height;
    padding: 0 1em 0 0;
    color: $text-color;
    margin: 0;
    span {
      @include responsive-font-size(24px);
    }
    &:focus,
    &:hover {
      text-decoration: none;
    }

    img {
      height: $navigation-height;
      vertical-align: top;
      padding: .75em 0;
      margin: 0 10px 0 0;
    }
  }

  .drop-vertical-menu {
    @extend .container;
    position: absolute;
    z-index: 999;
    top: 65px;
    background: white;
    @include box-shadow(0 4px 6px rgba(0, 0, 0, .25));
    display: none;
    @include clearfix;
    padding: 1em;
    padding-left: 0;
    a {
      color: $text-color;
    }
    &.focus {
      display: block;
    }
    .menu-left {
      width: 25%;
      float: left;
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        list-style: none;
        margin: 0;
        padding: 0;
      }
      a {
        display: block;
        line-height: 34px;
        padding: 0;
        height: auto;
        padding-left: 15px;
        &:hover, &.focus {
          background: $body-bg;
        }
      }
    }
    .menu-right {
      width: 75%;
      float: right;
      background: $body-bg;
      padding: .5em 0 .5em 15px;
      .menu-container {
        display: none;
        @include clearfix;
        &.focus {
          display: block;
        }
      }
      .menu-block {
        float: left;
        width: 33%;
        list-style: none;
        padding: 0;
        margin: 0;
      }
      li {
        list-style: none;
        padding: 0;
        margin: 0;
      }
      a {
        display: block;
        line-height: 34px;
        padding: 0;
        height: auto;
        padding-left: 15px;
        &:hover {
          background: darken($body-bg, 5%);
        }
      }
    }
  }

  a {
    display: block;
    height: $navigation-height;
    line-height: $navigation-height;
    @media (min-width: $screen-md-min) {
      color: $navigation-color;
      font-weight: 400;
      text-decoration: none;
      padding: 0 .5em;
      display: inline-block;
      border: none;
      border-left: solid transparent 1px;
      border-right: solid transparent 1px;

      &:focus,
      &:hover {
        color: $navigation-active-link-color;
      }
    }
    @media (min-width: $screen-lg-min) {
      padding: 0 1em;
    }
  }

  // Nav menu
  nav {
    float: none;
    min-height: $navigation-height;
    @media (min-width: $screen-md-min) {
      float: right;
    }

    ul {
      -webkit-transform-style: preserve-3d; // stop webkit flicker
      clear: both;
      margin: 0 auto;
      overflow: visible;
      padding: 0;
      width: 100%;
      z-index: 99;
      position: relative;
      border-bottom-right-radius: 3px;
      border-bottom-left-radius: 3px;
      @include box-shadow(0 1px 2px rgba(0, 0, 0, .25));
      @media (min-width: $screen-md-min) {
        display: inline-block;
        margin: 0;
        @include box-shadow(none);
      }
    }

    // The nav items
    ul li {
      @media (min-width: $screen-md-min) {
        background: $navigation-background;
        display: inline;
        padding: 0;
        margin: 0;
        list-style: none;
        text-decoration: none;
        width: auto;
        border: none;
        font-size: .9em;
        &.button {
          padding: .6em 0;
          margin: 0 .5em;
          background: $brand-secondary;
          border-radius: 3px;
          a {
            color: white;
            &:focus,
            &:hover {
              color: $text-color;;
              background: none;
            }
          }
        }
      }

      @media (min-width: $screen-md-min) {
        font-size: 1em;
      }
    }

    @media (min-width: $screen-md-min) {
      .active-nav-item a {
        color: $navigation-active-link-color;
        background: $body-bg;
        border-color: $border-color-base;
      }
    }

    // Elements on the far right
    .navigation-tools {
      background: $gray-lighter;
      margin: 0 -15px;
      clear: both;
      display: block;
      height: $navigation-height;

      @media (min-width: $screen-md-min) {
        background: transparent;
        clear: none;
        float: right;
        margin: 0;
      }
    }
  }

  .sliding-panel-button {
    color: $navigation-color;
    float: right;
    font-size: 1.6em;
    line-height: $navigation-height;
    background-color: $navigation-background;
  }
}
