.hover-tile {
	a {
		outline: none;
	}

	figure {
		position: relative;
		overflow: hidden;
		margin: 10px 0;
		cursor: pointer;
		@include box-shadow(0 1px 2px rgba(0, 0, 0, .2));

		img {
			position: relative;
			display: block;
			width: 100%;
		}

		figcaption {
			color: #fff;
			-webkit-backface-visibility: hidden;
			backface-visibility: hidden;
			@include transform(translate3d(0, 100%, 0));
			@include transition-property(transform);
			@include transition-duration(0.35s);
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

		.heading {
			height: 25%;
			width: 100%;
			background: #fff;
			text-align: center;
			bottom: 0;
			left: 0;
			position: absolute;
			color: $brand-primary;
			@include pad(3% 0);
			.title {
				margin: 0;
			}
		}
		.description {
			position: absolute;
			bottom: 25%;
			height: 75%;
			left: 0;
			right: 0;
			background: rgba(0, 0, 0, .65);
			font-size: 90%;
			opacity: 0;
			@include pad(3% 0);
			-webkit-backface-visibility: hidden;
			backface-visibility: hidden;
			@include transition(opacity 0.35s);
		}

		&:hover {
			@media (min-width: $screen-md-min) {
				figcaption {
					@include transform(translate3d(0, 0, 0));
				}
				.description {
					opacity: 1;
				}
			}
		}
	}

	&.hover-tile--list {
		a {
			@media (min-width: $screen-sm-min) {
				@include make-sm-column(4, 20px);
			}
			@media (min-width: $screen-lg-min) {
				@include make-lg-column(3, 20px);
			}
		}
	}
}
