/* Social Media */
.bg-facebook {background: #3B5998;color:white;&:hover {color:white;opacity:.95;}}
.bg-twitter {background: #55ACEE;color:white;&:hover {color:white;opacity:.95;}}
.bg-google-plus {background: #DD4B39;color:white;&:hover {color:white;opacity:.95;}}
.bg-pinterest {background: #CB2027;color:white;&:hover {color:white;opacity:.95;}}
.bg-instagram {background: #3F729B;color:white;&:hover {color:white;opacity:.95;}}
.bg-youtube {background: #ed5441;color:white;&:hover {color:white;opacity:.95;}}

.text-facebook {
	color: #3B5998 !important;
	background:white;
}
.text-twitter {
	color: #55ACEE !important;
	background:white;
}
.text-google-plus {
	color: #DD4B39 !important;
	background:white;
}
.text-pinterest {
	color: #CB2027 !important;
	background:white;
}
.text-instagram {
	color: #3F729B !important;
	background:white;
}
.text-youtube {
	color: #ed5441 !important;
	background:white;
}