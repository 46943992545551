.loading-overlay {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: white;
    opacity: .9;
    z-index: 999999;
    .loading-overlay__content {
        @include size(200px);
        display: block;
        position: absolute;
        top: 30%;
        left: 50%;
        text-align: center;
        line-height: 1.8;
        margin-left: -100px;
        @include responsive-font-size(2em);
        p {
            text-shadow: 0 0 1px rgba(0, 0, 0, .5);
        }
    }
    .loading-overlay__icon {
        font-size: 2em;
        display: block;
        @include center-block;
        text-shadow: 0 0 1px rgba(0, 0, 0, .5);
    }
}

body.loading {
    overflow: hidden;
    .loading-overlay {
        display: block;
    }
}