.location {
	display: block;
	margin: 0;
	padding: 0;
	text-align: right;
}
.location--left-aligned {
	text-align: left;
}

.location__street {
	display: block;
}