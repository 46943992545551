@mixin responsive-font-size($large-font-size, $minimum: false) {
	@if $minimum {
		font-size: $minimum;
	}
	@else {
		font-size: $large-font-size * .85;
	} 
	@media (min-width: $screen-sm-min) {
		font-size: $large-font-size * .9; 
	}
	@media (min-width: $screen-md-min) {
		font-size: $large-font-size * .95; 
	}
	@media (min-width: $screen-lg-min) {
		font-size: $large-font-size; 
	}
}

@mixin responsive-height($large-height, $minimum: false) {
	@if $minimum {
		height: $minimum;
	}
	@else {
		height: $large-height * .85;
	} 
	@media (min-width: $screen-sm-min) {
		height: $large-height * .9; 
	}
	@media (min-width: $screen-md-min) {
		height: $large-height * .95; 
	}
	@media (min-width: $screen-lg-min) {
		height: $large-height; 
	}
}

@mixin responsive-square($size, $minimum: false) {
	@if $minimum {
		height: $minimum;
		width: $minimum;
	}
	@else {
		height: $size * .85;
		width: $size * .85;
	} 
	@media (min-width: $screen-sm-min) {
		height: $size * .9; 
		width: $size * .9; 
	}
	@media (min-width: $screen-md-min) {
		height: $size * .95;
		width: $size * .95;
	}
	@media (min-width: $screen-lg-min) {
		height: $size;
		width: $size;
	}
}