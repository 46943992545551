.search-bar {
  margin: 1em 0;
  @media (min-width: $screen-sm-min) {
    margin: 1.5em auto;
    max-width: 80%;
  }
  @media (min-width: $screen-md-min) {
    max-width: 60%;
  }
  &.full-width {
    @media (min-width: $screen-sm-min) {
      max-width: 100%;
    }
    @media (min-width: $screen-md-min) {
      max-width: 100%;
    }
  }
  position: relative;
  z-index: 99;
  text-align: center;
  border-radius: 30px;
  .search-bar__label {
    @include responsive-font-size(22px, 16px);
    font-weight: normal;
  }
  .search-bar__btn {
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    border: none;
    background: none;
    position: absolute;
    display: block;
    left: 1px;
    bottom: 1px;
    top: 0;
    color: $brand-secondary;
    padding: 0 10px 0 15px;
    line-height: 48px !important;
    @media (min-width: $screen-md-min) {
      line-height: 58px !important;
    }
    @include responsive-font-size(1.6em);
  }
  input {
    border-radius: 30px;
    border-width: 2px;
    height: 50px;
    padding-left: 45px;
    border: solid lighten($brand-primary, 30) 1px;
    @include box-shadow(0 0 2px rgba(0, 0, 0, .25));
    background: rgba(255, 255, 255, .3);
    color: rgba(0, 0, 0, .5);
    @media (min-width: $screen-md-min) {
      height: 60px !important;
      padding-left: 55px;
    }
    @include responsive-font-size(20px, 16px);
    &:focus {
      @include box-shadow(0 0 6px rgba(0, 0, 0, .35));
    }
    @include placeholder {
      color: rgba(0, 0, 0, .5);
      opacity: .6;
    }
  }
}