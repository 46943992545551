@mixin card-span($size) {
	@for $i from 1 to $grid-columns {
		&.span-#{$size}-#{$i} {
			// Ignore float for the largest grid size
			@if $i < $grid-columns {
				@include span-columns($i, block-collapse);
				+ .card__content {
					border-top: none;
					border-left: solid darken($body-bg, 5) 1px;
				}
			}
		}
	}
}

.card {
	$background-color: white;
	$title-color: $brand-primary;
	$title-icon-color: $brand-secondary;

	background: $background-color;
	@include box-shadow(0 1px 2px rgba(0, 0, 0, .2));
	margin: 2em 0;
	padding: 0 !important;

	.card__heading {
		background: $title-color;
		color: $background-color;
		padding: .5em 1em;
		margin: 0;
	}

	.card__heading + .card__heading {
		border-top: solid darken($title-color, 10) 1px;
	}

	.card__footer {
		background: darken($background-color, 3%);
		border-top: solid darken($body-bg, 5) 1px;
		padding: 1.5em;
	}

	&.card--span {
		@include clearfix;
		.card__content {
			height: 100%;
			@media (min-width: $screen-sm-min) {
				@include card-span(sm);
			}
			@media (min-width: $screen-md-min) {
				@include card-span(md);
			}
		}
	}

	.card__steps {
		display: table;
		width: 100%;
		a {
			display: table-cell;
			width: 1%;
			line-height: 45px;
			text-align: center;
			border-top: solid $border-color-base 1px;
			.steps__icon {
				line-height: 40px !important;
				font-size: 1.2em;
			}
			+ a {
				border-left: solid $border-color-base 1px;
			}
			&.active {
				background: $brand-primary;
				color: white;
				border-color: $brand-primary;
				&:hover {
					background: $brand-primary;
					color: white;
				}
			}
			&:hover {
				text-decoration: none;
				background-color: $body-bg;
			}
		}
	}

	.card__content {
		padding: .75em;
		@media (min-width: $screen-sm-min) {
			padding: 1.5em;
		}
		margin: 0;
		.content__title, h1 {
			color: $title-color;
			margin: 0 0 .5em;
		}
	}

	.card__content + .card__content {
		border-top: solid darken($body-bg, 5) 1px;
		> h1, h2 {
			margin-top: 0;
		}
	}

	.content__title {
		color: $title-color;
		margin: 0 0 .5em;
	}

	.title__icon {
		color: $title-icon-color;
		padding-right: .25em;
		&.title__icon--right {
			padding-right: 0;
			padding-left: .25em;
		}
	}

	&.card--divide {
		background: darken($body-bg, 15%);
		.card__outer {
			background: #fff;
			@include box-shadow(0 0 6px rgba(0, 0, 0, .25));
			h1 {
				margin-top: 0;
			}
		}
		.card__inner {
			
		}
	}
}

.showcase {
	$background-color: white;
	$title-color: $brand-primary;
	$title-icon-color: $brand-secondary;

	text-align: center;
	@include clearfix;
	display: block;
	a.btn {
		@include button-variant(white, $brand-secondary, darken($brand-secondary, 10));
	}
	.showcase__icon {
		@include responsive-font-size(3.5em);
		color: $title-color;
		
	}
	&.showcase--option {
		color: $brand-secondary;
		&:hover, &:focus {
			text-decoration: none;
			background: $body-bg;
		}
	}
	&.showcase--inverse {
		color: $background-color !important;
		background-color: $title-color !important;
		a {
			@include button-variant(white, $brand-orange, darken($brand-orange, 10));
		}
		.showcase__icon {
			color: $background-color;
		}
	}
}