.stats {
  $base-spacing: .5em !default;
  $dark-gray: #333 !default;
  $base-font-color: $dark-gray !default;
  $action-color: #477dca !default;
  $stats-color: lighten($action-color, 10%);

  padding: 5px;

  ul {
    padding: 0;
    margin: 0;
  }

  li {
    border-right: 1px solid transparentize($base-font-color, 0.8);
    color: $stats-color;
    display: inline;
    float: left;
    font-size: 1.2em;
    line-height: 1.1em;
    padding: 0 0.7em;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      border-right: 0;
    }
  }

  span {
    color: $base-font-color;
    display: block;
    font-size: 0.7em;
    font-weight: normal;
  }
}
