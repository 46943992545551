$badge-font-size: 0.75em !default;
$medium-gray: #999 !default;
$alert-color: #fff6bf !default;
$error-color: #fbe3e4 !default;
$notice-color: #e5edf8 !default;
$success-color: #e6efc2 !default;

@mixin badge($color: $medium-gray) {
  background-color: $color;
  border-radius: $badge-font-size * 5;
  color: darken($color, 60%);
  display: inline-block;
  font-size: $badge-font-size;
  font-weight: 600;
  line-height: 1;
  padding: 0.4em 1.2em;
}

.badge {
  @include badge;
}

.badge-alert {
  @include badge($alert-color);
}

.badge-error {
  @include badge($error-color);
}

.badge-notice {
  @include badge($notice-color);
}

.badge-success {
  @include badge($success-color);
}

.badge-small {
  font-size: $badge-font-size * .6;
}

.badge-large {
  font-size: $badge-font-size * 1.5;
}
